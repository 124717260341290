import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import Dashboard from '../pages/Dashboard';
import Detail from '../pages/Detail';
import Confirmation from '../pages/Confirmation';
import Header from '../components/Header';

const { Navigator, Screen } = createStackNavigator();

const AppRoutes: React.FC = () => (
    <Navigator screenOptions={{ headerShown: false }}>
        <Screen name="Dashboard" component={Dashboard} />
        <Screen
            name="Detail"
            component={Detail}
            options={{
                headerShown: true,
                header: () => <Header showCancel={false} title="Detalhes" />
            }}
        />
        <Screen
            name="Confirmation"
            component={Confirmation}
            options={{
                headerShown: true,
                header: () => <Header showCancel={true} title="Confirmação" />
            }}
        />
    </Navigator>
);

export default AppRoutes;