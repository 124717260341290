import React, { useState } from 'react';
import {
  View,
  Image,
  StyleSheet,
  Text,
  Platform,
  TextInput,
  ScrollView,
  Dimensions,
} from 'react-native';
import { BorderlessButton, RectButton } from 'react-native-gesture-handler';
import { useAuth } from '../../contexts/auth';
import { Feather } from '@expo/vector-icons';
import moment from 'moment';

import logoImg from '../../images/gourmetsa.png';
import api from '../../services/api';
import { useNavigation } from '@react-navigation/native';

import { cpfCnpj } from '../../utils/functions';

import Footer from '../../components/Footer';
import { SafeAreaView } from 'react-native-safe-area-context';

interface LicenceData {
  id: number;
  idCliente: number;
  idLicenca: number;
  cpfCnpj: string;
  licChave: string;
  nomeFantasia: string;
  razaoSocial: string;
  nomeCidade: string;
  nomeRepresentante: string;
  dtValidade: Date;
  qtdProrrog: number;
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: '#3E3B47',
    margin: 0,
    // border: 0,
    alignItems: 'center',
  },
  container: {
    flex: 1,
    height: Dimensions.get('window').height,
    //backgroundColor: '#3E3B47',
    padding: 0,
    paddingTop: Platform.OS === 'web' ? 80 : Platform.OS === 'ios' ? 50 : 30,
    minWidth: 325,
    maxWidth: 325,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
  },

  content: {
    flexDirection: 'row',
    //padding: 20,
  },

  logo: {
    width: 200,
    height: 50,
  },

  input: {
    backgroundColor: '#232129',
    border: 0,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    height: 56,
    paddingVertical: 10,
    paddingHorizontal: 24,
    // marginBottom: 5,
    textAlignVertical: 'center',
    fontSize: 18,
    color: '#fff',
    width: '85%',
    outlineWidth: 0,
  },

  searchBox: {
    borderTopEndRadius: 10,
    borderBottomEndRadius: 10,
    height: 56,
    width: '15%',
    overflow: 'hidden',
  },

  searchButton: {
    backgroundColor: '#FF6857',
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },

  button: {
    backgroundColor: '#15c3d6',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    marginBottom: 16,
    width: 300,
  },

  buttonText: {
    fontSize: 16,
    color: '#FFF',
  },

  card: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 5,
    padding: 10,
  },

  cardTitle: {
    color: '#FF6857',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 30,
  },

  cardText: {
    color: '#b3aeae',
    lineHeight: 20,
  },

  image: {
    width: Dimensions.get('window').width,
    // height: auto,
    resizeMode: 'cover',
  },

  detailButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 5,
    padding: 10,
  },
});

const Dashboard: React.FC = () => {
  const { signOut, user } = useAuth();
  const navigation = useNavigation();

  const [search, setSearch] = useState('');
  const [licencas, setLicencas] = useState<LicenceData[]>([]);

  function handleSignOut() {
    signOut();
  }

  async function handleSearch() {
    console.log(search);
    try {
      const response = await api.get('/licenca', {
        params: {
          search,
        },
      });

      console.log(response.data);

      setLicencas(response.data);
    } catch {
      signOut();
    }
  }

  function handleNavigateToDetail(id: number) {
    navigation.navigate('Detail', { id });
  }

  return (
    <SafeAreaView style={styles.body}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Image style={styles.logo} source={logoImg} />
          <BorderlessButton onPress={handleSignOut}>
            <Feather name='log-out' size={30} color='#666360' />
          </BorderlessButton>
        </View>
        <View style={styles.content}>
          <TextInput
            style={styles.input}
            autoCorrect={false}
            autoCapitalize='none'
            value={search}
            placeholder='Localizar'
            placeholderTextColor='#666360'
            onChangeText={setSearch}
          />
          <View style={styles.searchBox}>
            <RectButton style={styles.searchButton} onPress={handleSearch}>
              <Feather name='search' size={25} color='#FFF' />
            </RectButton>
          </View>
        </View>
        <ScrollView
          style={{ marginBottom: 70, marginTop: 5 }}
          contentContainerStyle={{ paddingTop: 10, paddingBottom: 10 }}
        >
          {licencas.map((licenca) => {
            return (
              <View key={licenca.id} style={styles.card}>
                <BorderlessButton
                  onPress={() => handleNavigateToDetail(licenca.id)}
                >
                  <Text style={styles.cardTitle}>{licenca.nomeFantasia}</Text>
                </BorderlessButton>
                <Text style={styles.cardText}>{licenca.razaoSocial}</Text>
                <Text style={styles.cardText}>{cpfCnpj(licenca.cpfCnpj)}</Text>
                <Text style={styles.cardText}>{licenca.nomeCidade}</Text>
                <Text style={styles.cardText}>{licenca.nomeRepresentante}</Text>
                <Text style={styles.cardText}>
                  [ CT {licenca.id} ] [{' '}
                  {moment(licenca.dtValidade).format('DD/MM/YYYY')}
                  {licenca.qtdProrrog > 0 && ` +${licenca.qtdProrrog}`} ]
                </Text>
              </View>
            );
          })}
          {/* <Image
                    source={{ uri: 'http://192.168.100.5:9000/files/3.jpeg' }}
                    style={styles.image}>
                </Image> */}
        </ScrollView>
        <Footer />
      </View>
    </SafeAreaView>
  );
};

export default Dashboard;
