import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

export default function Footer() {
  return (
    <View style={styles.footer}>
      <Text style={styles.footerText}>SOFTSA Sistemas de Informação LTDA</Text>
      <Text style={styles.footerText}>Copyright © 2007~2021</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    flex: 1,
    position: 'absolute',
    paddingBottom: 20,
    bottom: 0,
  },

  footerText: {
    color: '#F4EDE8',
    fontSize: 14,
  },
});
