import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Platform,
  Dimensions,
  TouchableOpacity,
} from 'react-native';

import Modal from 'modal-enhanced-react-native-web';

import { RectButton } from 'react-native-gesture-handler';
import { addHours, addDays, parseISO, isAfter, format } from 'date-fns';
import ptbr from 'date-fns/locale/pt-BR';

import api from '../../services/api';
import { cpfCnpj } from '../../utils/functions';
import { useAuth } from '../../contexts/auth';
import Footer from '../../components/Footer';
import { SafeAreaView } from 'react-native-safe-area-context';

interface LicenceDetailsRouteParams {
  id: number;
}

interface Licence {
  id: number;
  chave: string;
  idCliente: number;
  idLicenca: number;
  cpfCnpj: string;
  nomeFantasia: string;
  razaoSocial: string;
  nomeCidade: string;
  dtValidade: Date;
  dtProrrogacao: Date;
  qtdProrrog: number;
  nomeRepresentante: string;
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: '#3E3B47',
    margin: 0,
    // border: 0,
    padding: 0,
    alignItems: 'center',
  },
  container: {
    flex: 1,
    height: Dimensions.get('window').height,
    // backgroundColor: '#3E3B47',
    padding: 0,
    paddingTop: Platform.OS === 'ios' ? 50 : 30,
    minWidth: 325,
    maxWidth: 325,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },

  scroll: {
    flex: 1,
  },

  logo: {
    width: 200,
    height: 50,
    // marginLeft: 30,
    // marginBottom: 20,
  },

  input: {
    backgroundColor: '#232129',
    borderRadius: 10,
    height: 56,
    paddingVertical: 18,
    paddingHorizontal: 24,
    marginBottom: 16,
    textAlignVertical: 'top',
    fontSize: 18,
    color: '#fff',
  },

  card: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 5,
    padding: 10,
  },

  cardTitle: {
    // flex: 1,
    color: '#FF6857',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 30,
  },

  cardText: {
    color: '#b3aeae',
    lineHeight: 20,
  },

  button: {
    borderRadius: 10,
    marginTop: 10,
    padding: 10,
    elevation: 2,
  },

  buttonText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FFF',
  },

  modalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f1',
    marginTop: 30,
  },
  modalBox: {
    alignContent: 'center',
    color: '#0000',
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  modal: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#00ff00',
    padding: 100,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    fontSize: 20,
  },
  modalContentText: {
    fontSize: 20,
  },
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalButton: {
    backgroundColor: '#FF6857',
    padding: 12,
    margin: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  modalButtonText: {
    color: '#fff',
    fontSize: 18,
  },
});

const Confirmation: React.FC = () => {
  const route = useRoute();
  const [licence, setLicence] = useState<Licence>();
  const { signOut } = useAuth();
  const navigation = useNavigation();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');

  const [dtNewProrrog, setDtNewProrrog] = useState<Date>(new Date());

  const params = route.params as LicenceDetailsRouteParams;

  useEffect(() => {
    api.get(`licenca/${params.id}`).then((response) => {
      console.log(response.data);
      const dataValidade = addHours(parseISO(response.data.dtValidade), 4);
      const dataProrrogacao = addHours(
        parseISO(response.data.dtProrrogacao),
        4
      );

      let dataRef = new Date();

      if (isAfter(dataValidade, dataRef)) {
        dataRef = dataValidade;
      }

      if (isAfter(dataProrrogacao, dataRef)) {
        dataRef = dataProrrogacao;
      }

      dataRef = addDays(dataRef, 3);
      setDtNewProrrog(dataRef);
      setLicence(response.data);
    });
  }, []);

  async function handleProrrogar() {
    api.put(`licenca/${licence?.idLicenca}`).then(
      (response) => {
        navigation.goBack();
        //const { id } = params;
        //navigation.navigate('Detail', { id });
      },
      (error) => {
        handleAlert(error.response.data);
      }
    );
  }

  function handleAlert(message: string) {
    setModalText(message);
    setModalVisible(true);
  }

  if (!licence) {
    return (
      <View style={styles.body}>
        <View style={styles.container}>
          <Text style={styles.cardText}>Carregando...</Text>
        </View>
      </View>
    );
  }

  if (licence) {
    return (
      <SafeAreaView style={styles.body}>
        {modalVisible && (
          <Modal
            isVisible={modalVisible}
            style={styles.bottomModal}
            // swipeDirection='down'
          >
            <View style={styles.modalContent}>
              <Text style={styles.modalContentText}>{modalText}</Text>
              <TouchableOpacity onPress={() => setModalVisible(false)}>
                <View style={styles.modalButton}>
                  <Text style={styles.modalButtonText}> Ok </Text>
                </View>
              </TouchableOpacity>
            </View>
          </Modal>
        )}
        <View style={styles.container}>
          <View style={styles.card}>
            <Text style={styles.cardTitle}>{licence.nomeFantasia}</Text>
            <Text style={styles.cardText}>{licence.razaoSocial}</Text>
            <Text style={styles.cardText}>{cpfCnpj(licence.cpfCnpj)}</Text>
            <Text style={styles.cardText}>{licence.nomeCidade}</Text>
            <Text style={styles.cardText}>{licence.nomeRepresentante}</Text>
            <Text style={styles.cardText}>
              [ CT {licence.id} ] [{' '}
              {format(addHours(parseISO(licence.dtValidade), 4), 'dd/MM/yyyy', {
                locale: ptbr,
              })}
              {licence.qtdProrrog > 0 && ` +${licence.qtdProrrog}`} ]
            </Text>
            {licence.dtProrrogacao && (
              <Text style={styles.cardText}>
                [{' '}
                {format(
                  addHours(parseISO(licence.dtProrrogacao), 4),
                  'dd/MM/yyyy',
                  {
                    locale: ptbr,
                  }
                )}{' '}
                ]
              </Text>
            )}
          </View>
          <View style={styles.card}>
            <Text
              style={styles.cardText}
            >{`Prorrogar a licença até ${format(
              dtNewProrrog,
              'iii dd/MM/yyyy',
              { locale: ptbr }
            )} ?`}</Text>
            <View style={{ flexDirection: 'row', padding: 5 }}>
              <RectButton
                style={[styles.button, { backgroundColor: 'green' }]}
                onPress={() => {
                  handleProrrogar();
                }}
              >
                <Text style={styles.buttonText}> Sim </Text>
              </RectButton>
              <Text>{'  '}</Text>
              <RectButton
                style={[styles.button, { backgroundColor: 'red' }]}
                onPress={navigation.goBack}
              >
                <Text style={styles.buttonText}> Não </Text>
              </RectButton>
            </View>
          </View>
          <Footer />
        </View>
      </SafeAreaView>
    );
  }
};

export default Confirmation;
