import React, { useState } from 'react';
import {
  View,
  ScrollView,
  Image,
  Text,
  StyleSheet,
  Platform,
  TextInput,
  Dimensions,
} from 'react-native';
import { RectButton } from 'react-native-gesture-handler';
import { useAuth } from '../../contexts/auth';

import logoImg from '../../images/gourmetsa.png';
import Footer from '../../components/Footer';
import { SafeAreaView } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: '#3E3B47',
    margin: 0,
    // border: 0,
    padding: 0,
    alignItems: 'center',
  },
  container: {
    flex: 1,
    height: Dimensions.get('window').height,
    //backgroundColor: '#3E3B47',
    padding: 0,
    paddingTop: Platform.OS === 'ios' ? 50 : 30,
    minWidth: 325,
    maxWidth: 325,
  },

  header: {
    //flex: 1,
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'left',
    marginBottom: 30,
  },

  scroll: {
    flex: 1,
  },

  logo: {
    width: 200,
    height: 50,
    // marginLeft: 30,
    // marginBottom: 20,
  },

  input: {
    backgroundColor: '#232129',
    borderRadius: 10,
    height: 56,
    paddingVertical: 18,
    paddingHorizontal: 24,
    marginBottom: 16,
    textAlignVertical: 'top',
    fontSize: 18,
    color: '#fff',
  },

  button: {
    backgroundColor: '#FF6857',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    marginBottom: 16,
  },

  buttonText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#FFF',
  },
});

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const { signed, signIn, user } = useAuth();

  function handleSignIn() {
    console.log(email);
    console.log(senha);
    signIn(email, senha);
  }

  return (
    <SafeAreaView style={styles.body}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Image style={styles.logo} source={logoImg} />
        </View>
        {/* <ScrollView style={styles.scroll} contentContainerStyle={{ padding: 20 }}> */}
        <TextInput
          style={styles.input}
          autoCorrect={false}
          autoCapitalize='none'
          keyboardType='email-address'
          value={email}
          placeholder='Email'
          placeholderTextColor='#666360'
          onChangeText={setEmail}
        />
        <TextInput
          style={styles.input}
          value={senha}
          placeholder='Senha'
          placeholderTextColor='#666360'
          secureTextEntry
          onChangeText={setSenha}
        />
        <RectButton style={styles.button} onPress={handleSignIn}>
          <Text style={styles.buttonText}>Entrar</Text>
        </RectButton>
        <Footer />
      </View>
    </SafeAreaView>
  );
};

export default SignIn;
