import React, { useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { View, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import { RectButton } from 'react-native-gesture-handler';
import { format, parseISO, addHours } from 'date-fns';
import ptbr from 'date-fns/locale/pt-BR';

import api from '../../services/api';
import { cpfCnpj } from '../../utils/functions';

import Footer from '../../components/Footer';
import { SafeAreaView } from 'react-native-safe-area-context';

interface LicenceDetailsRouteParams {
  id: number;
}

interface Licence {
  id: number;
  chave: string;
  idCliente: number;
  idLicenca: number;
  cpfCnpj: string;
  nomeFantasia: string;
  razaoSocial: string;
  nomeCidade: string;
  dtValidade: Date;
  dtProrrogacao: Date;
  qtdProrrog: number;
  nomeRepresentante: string;
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: '#3E3B47',
    margin: 0,
    // border: 0,
    padding: 0,
    alignItems: 'center',
  },
  container: {
    flex: 1,
    height: Dimensions.get('window').height,
    // backgroundColor: '#3E3B47',
    padding: 0,
    paddingTop: Platform.OS === 'ios' ? 50 : 30,
    minWidth: 325,
    maxWidth: 325,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },

  scroll: {
    flex: 1,
  },

  logo: {
    width: 200,
    height: 50,
    // marginLeft: 30,
    // marginBottom: 20,
  },

  card: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 5,
    padding: 10,
  },

  cardTitle: {
    // flex: 1,
    color: '#FF6857',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 30,
  },

  cardText: {
    color: '#b3aeae',
    lineHeight: 20,
  },

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },

  button: {
    borderRadius: 10,
    padding: 10,
    elevation: 2,
  },

  buttonText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#FFF',
  },
});

const Detail: React.FC = ({ navigation }) => {
  const route = useRoute();
  //const navigation = useNavigation();
  const [licence, setLicence] = useState<Licence>();
  const [load, setLoad] = useState(true);

  const params = route.params as LicenceDetailsRouteParams;

  useEffect(() => {
    api.get(`licenca/${params.id}`).then((response) => {
      setLicence(response.data);
    });

    const unsubscribe = navigation.addListener('focus', () => {
      setLoad(!load);
    });

    return unsubscribe;
  }, [load, navigation]);

  function handleProrrogar(id: number) {
    navigation.navigate('Confirmation', { id });
  }

  if (!licence) {
    return (
      <View style={styles.body}>
        <View style={styles.container}>
          <Text style={styles.cardText}>Carregando...</Text>
        </View>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.body}>
      <View style={styles.container}>
        <View style={styles.card}>
          <Text style={styles.cardTitle}>{licence.nomeFantasia}</Text>
          <Text style={styles.cardText}>{licence.razaoSocial}</Text>
          <Text style={styles.cardText}>{cpfCnpj(licence.cpfCnpj)}</Text>
          <Text style={styles.cardText}>{licence.nomeCidade}</Text>
          <Text style={styles.cardText}>{licence.nomeRepresentante}</Text>
          <Text style={styles.cardText}>
            [ CT {licence.id} ] [{' '}
            {format(addHours(parseISO(licence.dtValidade), 4), 'dd/MM/yyyy', {
              locale: ptbr,
            })}
            {licence.qtdProrrog > 0 && ` +${licence.qtdProrrog}`} ]
          </Text>
          {licence.dtProrrogacao && (
            <Text style={styles.cardText}>
              [{' '}
              {format(
                addHours(parseISO(licence.dtProrrogacao), 4),
                'dd/MM/yyyy',
                {
                  locale: ptbr,
                }
              )}{' '}
              ]
            </Text>
          )}
          <View>
            <Text>{'  '}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <RectButton
              style={[styles.button, { backgroundColor: 'green' }]}
              onPress={() => {
                handleProrrogar(licence.id);
              }}
            >
              <Text style={styles.buttonText}> +3 </Text>
            </RectButton>
          </View>
        </View>
        <Footer />
      </View>
    </SafeAreaView>
  );
};

export default Detail;
