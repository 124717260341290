import React, { createContext, useState, useEffect, useContext } from 'react';
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import * as auth from '../services/auth';
import api from '../services/api';

interface User {
    nome: string;
    email: string;
}

interface AuthContextData {
    signed: boolean;
    user: User | null;
    loading: boolean;
    signIn(email: string, senha: string): Promise<void>;
    signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function loadStorageData() {
            const storageUser = await AsyncStorage.getItem('@RnAuth:user');
            const storageToken = await AsyncStorage.getItem('@RnAuth:token');

            await new Promise(resolve => setTimeout(resolve, 2000));

            if (storageUser && storageToken) {
                api.defaults.headers.Authorization = `Bearer ${storageToken}`;

                setUser(JSON.parse(storageUser));
            }

            setLoading(false);
        }

        loadStorageData();
    }, []);

    async function signIn(email: string, senha: string) {
        // const response = await auth.signIn();

        try {
            const response = await api.post('/token', {
                email,
                senha,
            });

            const { token, user } = response.data;

            setUser(user);

            api.defaults.headers.Authorization = `Bearer ${token}`;

            await AsyncStorage.setItem('@RnAuth:user', JSON.stringify(user));
            await AsyncStorage.setItem('@RnAuth:token', token);

        } catch (err) {
            Alert.alert('Erro', 'Login inválido !');
        }
    }

    async function signOut() {
        AsyncStorage.clear().then(() => {
            setUser(null);
        });
    }

    return (
        <AuthContext.Provider value={{ signed: !!user, user, loading, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);

    return context;
};